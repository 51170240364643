import { DataPoint, NumberFormatter } from '~/tenset-components'

export interface NumberDataPointProps {
  label: string
  value?: number
  suffix?: string
}

export function NumberDataPoint({
  label,
  value,
  suffix,
}: NumberDataPointProps) {
  const number = (
    <>
      {value ? <NumberFormatter value={value} /> : '-'}
      {suffix}
    </>
  )

  return <DataPoint label={label}>{number}</DataPoint>
}
