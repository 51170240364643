import { useTranslation } from 'react-i18next'

import { Namespace } from '~/i18n'
import {
  Button,
  ButtonVariant,
  GemInfo,
  H2,
  Icon,
  IconName,
  Tag,
} from '~/tenset-components'
import { TagAdapted, gemCardInfoAdapter } from '~/data'
import ReadMoreButton from '~/components/read-more-button'
import type { GemAttributesType } from '~/api/types'

interface GemsWithHeaderProps {
  header: string
  gems: GemAttributesType[]
  children?: JSX.Element
}

export function GemsWithHeader({
  header,
  gems,
  children,
}: GemsWithHeaderProps) {
  return (
    <section className="flex flex-col gap-12">
      <H2 isBold>{header}</H2>

      <div className="grid auto-rows-fr gap-8">
        {gems.map(gem => (
          <GemWithHeader key={gem.name} gem={gem} />
        ))}
      </div>

      {children}
    </section>
  )
}

function GemWithHeader({ gem }: { gem: GemAttributesType }) {
  const { t } = useTranslation([Namespace.COMMON])

  const tokenDataPrice = gem.tokenData.find(({ key }) => key === 'price')
  const subtitle = tokenDataPrice && gemCardInfoAdapter(tokenDataPrice).value

  const launchData = gem.launchData.filter(({ showOnCard }) => showOnCard)

  return (
    <GemInfo
      key={gem.name}
      title={gem.title}
      cover={gem.card.cover.data.attributes.url}
      items={launchData.map(item => gemCardInfoAdapter(item))}
      description={t(gem.card.description)}
      subtitle={subtitle}
      tag={
        <div className="flex gap-2">
          <TagAdapted status={gem.status} />
          {gem.tags &&
            gem.tags.map(tag => (
              <Tag key={tag.label} color={tag.color}>
                {tag.label}
              </Tag>
            ))}
        </div>
      }
      actions={
        <div className="flex flex-col md:flex-row gap-2">
          {gem.presaleLink && (
            <Button to={gem.presaleLink} variant={ButtonVariant.Primary}>
              {t('common:vesting')}&nbsp;
              <Icon name={IconName.ChevronRight16} />
            </Button>
          )}

          {(gem.name || gem.card.readMoreLink) && (
            <ReadMoreButton
              buttonVariant={ButtonVariant.Secondary}
              link={gem.card.readMoreLink || `/${gem.name}`}
            />
          )}
        </div>
      }
    />
  )
}
