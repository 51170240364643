import { DataPoint, CryptoCurrencyFormatter } from '~/tenset-components'

export interface CryptoCurrencyDataPointProps {
  label: string
  currency?: string
  value?: number
  stringValue?: string
}

export function CryptoCurrencyDataPoint({
  label,
  currency,
  value,
  stringValue,
}: CryptoCurrencyDataPointProps) {
  const cryptoCurrency = value ? (
    <CryptoCurrencyFormatter currency={currency} value={value} />
  ) : (
    <>- {currency}</>
  )

  return <DataPoint label={label}>{cryptoCurrency}</DataPoint>
}
