import type { GemInfoItem } from '~/tenset-components'
import {
  CryptoCurrencyFormatter,
  DataPoint,
  NumberFormatter,
  Tag,
  TagColor,
} from '~/tenset-components'
import {
  CryptoCurrencyDataPoint,
  NumberDataPoint,
  TokenPriceDataPoint,
} from '~/components/data-points'
import type { GemDataField } from '~/api/types'
import { GemStatus } from '~/api/types'

export const DataPointAdapted = ({
  field,
}: {
  field: GemDataField
}): JSX.Element => (
  <>
    {field.tokenPrice && (
      <TokenPriceDataPoint label={field.label} {...field.tokenPrice} />
    )}
    {field.number && <NumberDataPoint label={field.label} {...field.number} />}
    {field.currency && (
      <CryptoCurrencyDataPoint label={field.label} {...field.currency} />
    )}
    {field.data && (
      <DataPoint label={field.label}>
        {field.data.split('\n').map(item => (
          <p key={item}>{item}</p>
        ))}
      </DataPoint>
    )}
  </>
)

export const gemCardInfoAdapter = (field: GemDataField): GemInfoItem => {
  if (field.currency) {
    return {
      title: field.label,
      value: <CryptoCurrencyFormatter {...field.currency} />,
    }
  }

  if (field.number) {
    return {
      title: field.label,
      value: <NumberFormatter {...field.number} />,
    }
  }

  if (field.tokenPrice) {
    return {
      title: field.label,
      value: (
        <>
          <CryptoCurrencyFormatter
            value={field.tokenPrice.initialValue}
            currency={field.tokenPrice.initialValueCurrency}
          />
          &nbsp;{'='}&nbsp;
          <CryptoCurrencyFormatter
            value={field.tokenPrice.gotValue}
            currency={field.tokenPrice.gotValueCurrency}
          />
        </>
      ),
    }
  }

  return {
    title: field.label,
    value: field.data ?? '',
  }
}

export const TagAdapted = ({ status }: { status: GemStatus }): JSX.Element => {
  switch (status) {
    case GemStatus.FUTURE: {
      return <Tag color={TagColor.YELLOW}>In line</Tag>
    }
    case GemStatus.CURRENT: {
      return <Tag color={TagColor.GREEN}>Active</Tag>
    }
    case GemStatus.PREVIOUS: {
      return <Tag color={TagColor.NEUTRAL}>Completed</Tag>
    }
  }
}
