import { DataPoint, CryptoCurrencyFormatter } from '~/tenset-components'

export interface TokenPriceDataPointProps {
  label: string
  initialValue: number
  initialValueCurrency: string
  gotValue: number
  gotValueCurrency: string
}

export function TokenPriceDataPoint({
  label,
  initialValue,
  initialValueCurrency,
  gotValue,
  gotValueCurrency,
}: TokenPriceDataPointProps) {
  const tokenPrice = (
    <div>
      <CryptoCurrencyFormatter
        currency={initialValueCurrency}
        value={initialValue}
      />
      &nbsp;=&nbsp;
      <CryptoCurrencyFormatter currency={gotValueCurrency} value={gotValue} />
    </div>
  )

  return <DataPoint label={label}>{tokenPrice}</DataPoint>
}
